import React, { useState } from 'react'
import { mergeClassList } from '../../lib/utils'
import envelope from '../Blog/images/envelope.svg'
import * as CompaniesLogos from 'components/Logo'
import SubstackForm from 'components/SubstackForm'
import { useLayoutData } from 'hooks/useLayoutData'

export const NewsletterForm = ({
    sidebar = false,
    compact = false,
    subcompact = false,
    bgColor = '#08042f',
    className = '',
}: {
    sidebar?: boolean
    compact?: boolean
    subcompact?: boolean
    bgColor?: string
    className?: string
}): JSX.Element => {
    const { logo, brand } = useLayoutData()
    const [email, setEmail] = useState('')
    const classList = mergeClassList('w-full p-4 relative z-10 text-center', className)
    const Logo = logo && CompaniesLogos[logo]

    return sidebar ? (
        <div>
            <div className="flex items-end space-x-2">
                <div>
                    <img className="w-full max-w-[47px]" src={envelope} />
                </div>
                <p className="m-0 flex-shrink-0 text-sm font-bold leading-tight">
                    <span className="flex items-center space-x-2">
                        <span>The best of</span> <Logo className="w-[80px]" />
                    </span>{' '}
                    <span className="-mt-1 block text-xs">
                        Delivered <span className="text-red">twice</span> a month.
                    </span>
                </p>
            </div>
            <div className="mt-4 md:mt-2">
                <SubstackForm />
            </div>
        </div>
    ) : compact ? (
        <div className="mx-auto my-12 w-full text-center">
            <div className="flex h-full w-full justify-center border-t border-b border-dashed border-gray-accent-light p-1 dark:border-gray-accent-dark">
                <div className={classList}>
                    <div className="flex flex-col items-center md:flex-row md:space-x-4">
                        <figure className="m-0 shrink-0 grow-0 basis-12  dark:text-white">
                            <svg
                                className="mb-0 block h-12 fill-current"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 50 50"
                            >
                                <path
                                    d="M41.5 7.5c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7Z"
                                    fill="#0097c2"
                                />
                                <path d="M41.5 23.5c.34 0 .67-.02 1-.06V36.5c0 1.654-1.346 3-3 3h-32c-1.654 0-3-1.346-3-3v-20c0-1.654 1.346-3 3-3h25.06c-.04.33-.06.66-.06 1 0 2.125.74 4.074 1.97 5.615l-8.849 8.85a3.005 3.005 0 0 1-4.244 0l-11.172-11.17a1 1 0 1 0-1.414 1.414l11.17 11.17a4.984 4.984 0 0 0 3.535 1.46c1.28 0 2.56-.484 3.535-1.46l8.85-8.85A8.987 8.987 0 0 0 41.5 23.5Z" />
                            </svg>
                        </figure>

                        <span className="flex flex-grow flex-col font-bold md:flex-row md:justify-start md:space-x-2 md:text-left">
                            <span className="text-lg">{`The best of ${brand}.`}</span>{' '}
                            <span className="text-sm md:text-lg">Delivered twice a month.</span>
                        </span>
                    </div>
                    <div className="mt-2 md:ml-16 md:mt-0">
                        <SubstackForm />
                    </div>
                </div>
            </div>
        </div>
    ) : subcompact ? (
        <div className={classList}>
            <SubstackForm />
        </div>
    ) : (
        <div className="mb-6 flex h-full w-full rounded-md bg-white p-1 shadow-xl dark:bg-gray-accent-dark">
            <div className="relative z-10 flex w-full flex-col items-center space-y-2 p-4 md:flex-row md:space-x-12 md:space-y-0">
                <div className="flex items-center space-x-2">
                    <img className="mx-auto w-[75px]" src={envelope} />
                    <p className="m-0 font-bold leading-tight">
                        {`The best of ${brand}.`} <br />
                        Delivered <span className="text-red">twice</span> a month.
                    </p>
                </div>
                <div className="flex-grow">
                    <SubstackForm />
                </div>
            </div>
        </div>
    )
}
export default NewsletterForm
